import { render, staticRenderFns } from "./courseOrder.vue?vue&type=template&id=711d64cd&scoped=true"
import script from "./courseOrder.vue?vue&type=script&lang=js"
export * from "./courseOrder.vue?vue&type=script&lang=js"
import style0 from "./courseOrder.vue?vue&type=style&index=0&id=711d64cd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711d64cd",
  null
  
)

export default component.exports
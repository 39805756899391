<template>
  <div class="courseOrderBg">
    <img src="images/orderAffrim.png" style="width: 100%">
    <div  class="courserOdreFont" v-if="!postData">
      <p class="courserOdreFontpP"  v-for="(item,index) in courseDetailArr" :key="index" @click="changeOrderTab(index,item)" :class="orderShow==index?'orderFontActive' :''">{{item.name}}</p>
    </div>
    <div class="courseOrderPadding">
      <div style="display: flex;margin-bottom: 30px" v-if="userInfo&&!userInfo.teacherType&&vipEndTime">
        <div style="display: flex;margin-right: 60px">
          <p>你好,</p>
          <p class="userNames" v-if="userName||telNumber">{{userName?userName:telNumber}}</p>
          <p class="userNames" v-if="!userName&&!telNumber">{{userInfo.name?userInfo.name:userInfo.telNumber}}</p>
        </div>
        <div  class="VipTime" >
          <p>VIP于{{vipEndTime}}到期</p>
        </div>
      </div>
      <div style="margin-bottom: 30px" v-if="!userInfo">
          <p>你好,请登录后购买相关活动</p>
      </div>
      <div>
        <div class="orderBottom">
          <p class="orderTitle">订单信息</p>
          <template v-if="!courserType&&courserType!=='allTotal'&&!allPrice">
            <p class="orderCourseTitle">{{courseDetail.name}}</p>
            <div class="courseOrderFlexProduce" >
              <div >
                <p v-if=!courserType>
                  <span>章节:</span>
                  <span>{{courseDetail.chapterNumber}}个</span>
                </p>
                <p v-if=!courserType>
                  <span>难易度:</span>
                  <span>{{courseDetail.difficultyLevel}}</span>
                </p>
              </div>
                <div class="courseOrderFlexProduce" >
  <!--                <div>{{queryData.buyDay}}天</div>-->
                  <div class="orderCoursePriceSpan">
                    <span>课程价格:</span>
                    <div v-if=!courserType>
                      <span>¥{{ queryData.payNumber}}</span>
                      <span>(¥{{queryData.originalPrice}})</span>
                    </div>
                    <div v-if="courserType=='allTotal'">
                      <p style="font-size: 40px;color: #FF6103;font-weight: bold;">¥{{ courseDetail.price}}</p>
                    </div>
                  </div>
                </div>
              </div>
          </template>
          <template v-if="courserType&&courserType=='allTotal'&&!allPrice">
            <p class="orderCourseTitle">{{courseDetail.name}}</p>
            <div class="courseOrderFlexProduce" >
              <div >
                <p v-if=!courserType>
                  <span>章节:</span>
                  <span>{{courseDetail.chapterNumber}}个</span>
                </p>
                <p v-if=!courserType>
                  <span>难易度:</span>
                  <span>{{courseDetail.difficultyLevel}}</span>
                </p>
              </div>
              <div class="courseOrderFlexProduce" >
                <!--                <div>{{queryData.buyDay}}天</div>-->
                <div class="orderCoursePriceSpan">
                  <span>课程价格:</span>
                  <div v-if=!courserType>
                    <span>¥{{ queryData.payNumber}}</span>
                    <span>(¥{{queryData.originalPrice}})</span>
                  </div>
                  <div v-if="courserType=='allTotal'">
                    <p style="font-size: 40px;color: #FF6103;font-weight: bold;">¥{{ courseDetail.price}}</p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="courserType&&courserType=='allTotal'&&allPrice">
            <div class="allPriceBackground">
              <p class="orderCourseTitle">{{classPName}}</p>
              <p>{{classProfiles}}</p>
            </div>
            <div  style="display: flex;align-items: center">
<!--              <div class="orderBorder" :class="priceShow==index?'allPriceActive':''" v-for="(item,index) in allPriceArr" :key="index" @click="changeOrderPrice(item,index)">-->
<!--                <img src="images/VIP (1).png">-->
<!--                <p style="color: #999999;font-weight: bold">{{classPName}}</p>-->
<!--                <p class="allPriceColor">-->
<!--                  {{item.price}}-->
<!--                  <span>元</span>-->
<!--                  （不限次学）-->
<!--                </p>-->
<!--                <p style="color: #999999">{{item.vipName}}</p>-->
<!--              </div>-->
              <div class="allPriceArrFlex">
                <div  v-for="(item,index) in allPriceArr" :key="index" @click="changeOrderPrice(item,index)">
                  <div class="vipFlexWd" :class="priceShow==index?'allPriceActive':''" v-if="item.vipName=='半年卡'">
                    <div class="vipPricePadd">
                      <div class="vipPriceFlex">
                        <img src="images/vipIcon1.png"/>
<!--                        <p>{{item.vipName}}</p>-->
                      </div>
                      <p class="vipNameFont">—— {{classPName}} ——</p>
                      <div class="vipPriceFont">
                        <span>¥</span>
                        <span>{{item.price}}</span>
                        <span>（不限次学）</span>
                      </div>
                    </div>
                  </div>
                  <div class="vipFlexWds" :class="priceShow==index?'allPriceActive':''" v-if="item.vipName=='年度卡'">
                    <div class="vipPricePadd">
                      <div class="vipPriceFlex">
                        <img src="images/vipIcon2.png"/>
<!--                        <p>{{item.vipName}}</p>-->
                      </div>
                      <p class="vipNameFont">—— {{classPName}} ——</p>
                      <div class="vipPriceFont">
                        <span>¥</span>
                        <span>{{item.price}}</span>
                        <span>（不限次学）</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
<!--        支付方式-->
        <div>
          <p class="orderCourseTitle">支付方式</p>
<!--          <div class="orderRadioFlex">-->
<!--            <el-radio v-model="Payradio" label="1">-->
<!--              <div class="orderRadio">-->
<!--                <img src="images/wxPay.png" />-->
<!--                <span>微信</span>-->
<!--              </div>-->
<!--            </el-radio>-->
<!--            <el-radio v-model="Payradio" label="2">-->
<!--              <div class="orderRadio">-->
<!--                <img src="images/Alipay.png" />-->
<!--                <span>支付宝</span>-->
<!--              </div>-->
<!--            </el-radio>-->
<!--          </div>-->
          <div style="display: flex;align-items: center;padding: 20px;">
            <el-radio v-model="Payradio" label="1" border style="display: flex;align-items: center">
              <div style="display: flex;align-items: center">
                <span style="width: 300px;display: inline-block">微信</span>
                <img style="width: 34px;margin-left: 66px" src="images/weixin.png">
              </div>
            </el-radio>
            <el-radio v-model="Payradio" label="2" border style="display: flex;align-items: center">
              <div style="display: flex;align-items: center">
                <span style="width: 296px;display: inline-block">支付宝</span>
                <img style="width: 66px;margin-left: 34px" src="images/zhifubao.png">
              </div>
            </el-radio>
          </div>
        </div>
        <div class="orderBuyBtn">
          <el-button type="primary" @click="promptlyBuy">立即购买</el-button>
        </div>
      </div>
    </div>
    <div style="width: 1248px;margin: 0 auto;text-align: center">
      <p style="font-size: 22px;margin: 30px 0;">更多职位课程99元/年（起）</p>
      <p style="font-size: 14px">20+职位分类，单分类购买更实惠</p>
      <div style="margin: 40px 0">
        <div v-for="(item,index) in courseDetailArr" :key="index" >
          <div  class="workplaceClassfyFlexorder">
            <div v-for="(item1,index1) in item.children" :key="index1">
              <p  class="kernelCourseCenterClassfy" @click="goPostCourseDetail(item1,item)">
                <template v-if="item1.iconUrl">
                  <img  :src="item1.iconUrl?item1.iconUrl:''">
                </template>
                <span>{{item1.name}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #FF9900;padding: 10px 0">
        <div style="width:900px;display: flex;align-items: center;justify-content: center">
          <img src="images/VIP (2).png">
          <span>每个决定，都是一块基石奠定美好的未来！</span>
        </div>
    </div>
    <el-dialog :title="(Payradio===1?'支付宝':'微信') +'支付'" :visible.sync="qrcodeShow" width="250px" :destroy-on-close="true" @opened="openQrCodeImg" >
      <span class="codeimg" ref="qrcodeImg"></span>
    </el-dialog>
    <div ref="aliPay"></div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../footer.vue'
import QRCode from 'qrcodejs2'
import {
  createCourseOrVipOrder,
  findAllPrice,
  payMoney,
  payAliMoney,
  findOrderStatus,
  checkUserPayCourse,
  afrimOrder,
  courseClassify
} from "@/api";
  export default {
    components:{
      Footer
    },
    data(){
      return{
        courseDayNubmer:'',
        Payradio:'1',
        courseDetail:{},
        queryData:{},
        qrcodeShow: false,
        courserType:'',
        classPName:'',
        classProfiles:'',
        allPrice:'',
        allPriceArr:[],
        priceShow:'0',
        validDays:'',
        allprice:'',
        courseDetailArr:[],
        orderShow:0,
        userInfo:'',
        vipEndTime:'',
        userName:'',
        telNumber:'',
        postData:''
      }
    },
    methods:{
      //  去岗位课程详情
      goPostCourseDetail(item,bigItem){
        let classfiy=[]
        this.courseDetailArr.forEach((item1,index1)=>{
          if(item1.id==bigItem.id){
            item1.children.forEach(item2=>{
              if(item.id!==item2.id){
                classfiy.push(item2)
              }
            })
          }
        })
        sessionStorage.setItem('classfiy',JSON.stringify(classfiy))
        let newpage = this.$router.resolve({
          name: 'postCourseDetail',
          query:{
            classFyId:item.id,
            classPid:bigItem.id,
            classPName:bigItem.name,
            classProfiles:bigItem.profiles

          }
        })
        window.open(newpage.href);
      },
      //改变tab标签栏
      changeOrderTab(index,item){
        this.orderShow=index;
        this.classPName=item.name
        this.classProfiles=item.profiles
        let data={
          classPId:item.id
        }
        findAllPrice(data).then(res=>{
          if(res.code==0&&res.data.length){
            this.allPriceArr=res.data
            this.allprice=res.data[0].price;
            this.validDays=res.data[0].validDays
          }
        })
      },
      //课程分类列表
      getCourseClassify(){
        courseClassify().then(res => {
          this.courseDetailArr=res.data
          this.changeOrderTab(0,res.data[0])
        })
      },
    //  选择购买半年卡还是年卡
      changeOrderPrice(item,index){
        this.priceShow=index
        this.allprice=item.price
        this.validDays=item.validDays
      },
    //  获取全系列vip大类信息
      getfindAllPrice(){
        let data={
          classPId:this.$route.query.classpId
        }
        findAllPrice(data).then(res=>{
          if(res.code==0){
            this.allPriceArr=res.data
            this.allprice=res.data[0].price;
            this.validDays=res.data[0].validDays
          }
        })
      },
    //  创建订单支付
      promptlyBuy(){
        if(this.$route.query.type=='allTotal'){
          let data1={
            goodsType:'COURSE',
            price:this.allprice,
            days:this.validDays,
            terminalType:'WEB',
            coursePid:this.$route.query.classpId
          }
          let data={
            goodsType:'COURSE',
            days:'365',
            terminalType:'WEB',
          }
          if(!this.$route.query.allPrice){
            data.price=this.courseDetail.price
            data.courseIds=this.courseDetail.courseIds
            data.classifyId=this.courseDetail.id
          }


          afrimOrder(this.$route.query.allPrice?data1:data).then(res=>{
            this.orderId=res.data.id;
            this.doPay();
          })
        }else{
          let data={
            terminalType:'PC',
            goodsType:'COURSE',
            goodsSkuId:this.$route.query.skuId
          };
          createCourseOrVipOrder(data).then(res=>{
            this.orderId=res.data.id;
            this.doPay();
          })
        }
      },
      //支付方式
      doPay(){
        if(!this.orderId)return this.$message.error('系统异常');
        if(this.Payradio==1){
          let pay=setInterval(( ) => {
            sessionStorage.setItem("pay", pay);
            let data={
              id:this.orderId
            }
            findOrderStatus(data).then(res=>{
              if(res.data==true){
                this.$message({
                  message: "支付成功",
                  type: "success",
                });
                clearInterval(pay)
                if(this.$route.query.type=='allTotal'){
                  this.$router.push('./postCourseDetail')
                }else{
                  this.$router.push('./courseVideoDetais')
                }
              };
            })
          }, 3000)

          this.wxPay(this.orderId);
        }else{
          this.Alipay(this.orderId)
        }
      },
      //微信支付
      wxPay(orderId) {
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'WX'
        };
        payMoney(data).then(res=>{
          if(res.code==0){
            this.qrcodeImg =JSON.parse(res.data).code_url;
            this.qrcodeShow = true;

          }
        })
      },
      //支付宝支付
      Alipay(orderId){
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'ALI'
        };
        payAliMoney(data).then(res=>{
          this.$refs.aliPay.innerHTML = res;
          document.getElementsByName('punchout_form')[0].submit()
        })
      },
      // 生成二维码
      openQrCodeImg(){
        new QRCode(this.$refs.qrcodeImg, {
          width: 200,
          height: 200,
          text: this.qrcodeImg,
          render: 'canvas'
        });
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',4);
      if(this.$route.query.path=='post'){
        let courseDetail = sessionStorage.getItem('courseVideoDetails')
        this.courseDetail = JSON.parse(courseDetail);
      }else if(this.$route.query.type=='allTotal'){
        this.courserType=this.$route.query.type
        let courseAllTotal = sessionStorage.getItem('courseAllTotal')
        this.courseDetail = JSON.parse(courseAllTotal);
        this.postData=this.$route.query.postData
      }else{
        let courseDetail = sessionStorage.getItem('courseDetail')
        this.courseDetail = JSON.parse(courseDetail);
      }
      this.queryData=this.$route.query
      this.getfindAllPrice()
      this.classPName=this.$route.query.classPName
      this.classProfiles=this.$route.query.classProfiles;
      this.allPrice=this.$route.query.allPrice;
      this.getCourseClassify();
      this.orderShow=this.$route.query.orderShow;
      this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
      if(this.userInfo.vipEndTime&&this.userInfo.vipEndTime!==null){
        this.vipEndTime=this.userInfo.vipEndTime.slice(0,10)
      }
      this.userName = this.$cookie.get("userName");
    },
    computed: {
      storeNickName(){
        this.userName = this.$store.state.userName;
      },
      storeUserPhone(){
        this.telNumber = this.$store.state.telNumber;
      },
      localPhone(){
        let newUserinfo=JSON.parse(localStorage.getItem("userInfo")) ;
        if(newUserinfo&&newUserinfo.name){
          this.userName = newUserinfo.name;
        }else if(newUserinfo&&newUserinfo.telNumber){
          this.telNumber = newUserinfo.telNumber;
        }else if(newUserinfo&&!newUserinfo.telNumber&&!newUserinfo.name&&newUserinfo.teacherType){
          this.telNumber = newUserinfo.account;
        }
      }
    },
    watch:{
      "$route":"getPath",
      // 不要删
      storeUserPhone:{
        handler(item){
        },
        immediate: true,
        deep: true
      },
      // 不要删
      storeNickName:{
        handler(item){

        },
        immediate: true,
        deep: true
      },
      localPhone:{
        handler(item){
        },
        immediate: true,
        deep: true
      },
    },
  }
</script>
<style scoped>
@import "../../../public/css/courseOrder.css";
</style>
